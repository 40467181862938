@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500&display=swap');
@import "~bootstrap/scss/bootstrap";

:root {
  --blue: #236f94;
  --darkBlue: #0e3e5e;
  --purple: #534d74;
  --white: #ffffff;
  --darkWhite: #cfd2d4;
  --black: #191715;
}

@mixin radial-background($from, $to) {
  background: radial-gradient(circle, $from 50%, $to 150%);
}

@mixin transition-opacity($delay, $delayed: false) {
  transition: opacity $delay;

  @if $delayed {
    transition-delay: $delay;
  }
}

body {
  margin: 0;
  @include radial-background(var(--white), var(--white));

  #root {
    height: 100vh;

    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: var(--black);
      @include transition-opacity(750ms);
    }

    #app {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;

      .form-control:focus {
        border-color: #ffffff;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
      }

      #content {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin: 0 1rem;
        flex-direction: column;

        #alert {
          position: absolute;
          top: 20%;
          width: 40%;
          z-index: 99;
        }

        #yellow {
          .eye {
            animation: yellow-eye 10s infinite;
          }

          .eyelid {
            opacity: 0;
            animation: yellow-eyelid 10s infinite;
          }
        }

        #blue {
          .eye {
            animation: blue-eye 10s infinite;
          }

          .eyelid {
            opacity: 0;
            animation: blue-eyelid 10s infinite;
          }
        }

        .head {
          @include transition-opacity(250ms, true);
        }

        .main {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 75vh;
          align-items: center;
          padding: 1rem;
          z-index: 1;
          @include transition-opacity(250ms, true);

          span {
            font-family: 'Nunito', sans-serif;
            font-weight: 300;
            color: var(--white);
            text-transform: uppercase;

            h1 {
              font-size: 1.75rem;
            }

            h2 {
              font-size: 1rem;
              text-align: center;
            }
          }
        }
      }

      .btnIcon {
        background-color: DodgerBlue; /* Blue background */
        border: none; /* Remove borders */
        color: white; /* White text */
        padding: 12px 16px; /* Some padding */
        font-size: 16px; /* Set a font size */
        cursor: pointer; /* Mouse pointer on hover */
      }

      .footer {
        margin: 0 auto;
        z-index: 1;

        .dot {
          @include transition-opacity(250ms);
        }
      }
    }
  }
}

@keyframes yellow-eye {
  12.5%,
  22.5%,
  52.5%,
  67.5% {
    transform: matrix(0.866, 0.50003, 4e-5, 1, 35.355, 102.057);
  }
  15%,
  20% {
    transform: matrix(0.866, 0.50003, 4e-5, 1, 30.406, 104.915);
  }
  55%,
  65% {
    transform: matrix(0.866, 0.50003, 4e-5, 1, 42.426, 106.14);
  }
}

@keyframes yellow-eyelid {
  30%,
  35%,
  75%,
  80% {
    opacity: 0;
  }
  32.5%,
  77.5% {
    opacity: 1;
  }
}

@keyframes blue-eye {
  20%,
  35%,
  65%,
  75% {
    transform: matrix(0.866, -0.50003, -4e-5, 1, 106.065, 193.057);
  }
  22.5%,
  32.5% {
    transform: matrix(0.866, -0.50003, -4e-5, 1, 98.995, 197.14);
  }
  67.5%,
  72.5% {
    transform: matrix(0.866, -0.50003, -4e-5, 1, 111.015, 195.915);
  }
}

@keyframes blue-eyelid {
  42.5%,
  47.5%,
  82.5%,
  87.5% {
    opacity: 0;
  }
  45%,
  85% {
    opacity: 1;
  }
}
